<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="item_no" slot-scope="item_no, record">
        <div v-if="isShowMenu('agent_purchase_items/show')">
          <a @click="showInfoModal(record.purchase_item_id)">{{ item_no }}</a>
        </div>
        <div v-else>
          {{ item_no }}
        </div>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agent-purchase-item
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findPurchaseBills } from '@/api/agent_monthly_bill'
import { hasPermission } from '@/utils/permission'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'AgentPurchaseBillList',
  components: {
    Pagination,
    ShowAgentPurchaseItem: () => import('@/views/agent_monthly_bills/purchase/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false,
      showingId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  props: {
    billId: {
      type: Number,
      required: true
    }
  },
  computed: {
    columns() {
      return [
        { title: '项目批次', dataIndex: 'item_no', scopedSlots: { customRender: 'item_no' }, width: 235 },
        { title: '账单时间', dataIndex: 'bill_time', width: 200 },
        { title: '运营商种类', dataIndex: 'carrier_type', width: 100 },
        { title: '客户套餐', dataIndex: 'agents_product_name', width: 200 },
        { title: '周期数', dataIndex: 'service_period', customRender: formatBigNumber, width: 80 },
        { title: '采购卡数(张)', dataIndex: 'card_count', customRender: formatBigNumber, width: 100 },
        { title: '套餐价格(元)', dataIndex: 'product_price', customRender: formatCurrency, width: 100 },
        { title: '卡板价格', dataIndex: 'card_price', width: 100 },
        { title: '套餐费用(元)', dataIndex: 'product_fee', customRender: formatCurrency, width: 100 },
        { title: '卡板费用(元)', dataIndex: 'card_fee', customRender: formatCurrency, width: 100 },
        { title: '账单金额(元)', dataIndex: 'total_fee', customRender: formatCurrency, width: 100 },
        { title: '备注', dataIndex: 'remark', width: 100, ellipsis: true }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    fetchData() {
      this.loading = true
      findPurchaseBills(this.billId, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>

